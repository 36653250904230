<template>
  <div>
    <div class="title">
      <h3>تقديم شكوي</h3>
    </div>
    <v-divider></v-divider>
    <v-container>
      <v-row>
        <h4 class="blue--text">
          شركه مياه الشرب والصرف الصحي بأسيوط والوادي الجديد
        </h4>
      </v-row>
      <v-row class="mt-5">
        <v-col class="subClass" cols="12" sm="8" xs="12" lg="12">
          <div>
            <!-- src="https://cms.hcww.com.eg/WebsiteComplaints/Web_CustomerComplaint?CID=20" -->
            <iframe
              style="border: 0px #ffffff none"
           
              src="https://cms.hcww.com.eg/WebsiteComplaints/Web_CustomerComplaint?CID=20"
              name="myiFrame"
              align="center"
              width="100%"
              height="850px"
              frameborder="0"
              marginwidth="0px"
              marginheight="0px"
              scrolling="yes"
             
            >
            </iframe>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script >
export default {
  components: {},
};
</script>
<style>
h3 {
  letter-spacing: normal;
}
</style>         
              
       
                 
     